import request from '@/utils/request'

const userApi = {
  supplier_list: '/supplier/',
  supplier_create: '/supplier/',
  supplier_update: '/supplier/',
  supplier_delete: '/supplier/',
  supplier_tag_create: '/supplier/tag/',
  supplier_tag_delete: '/supplier/tag/',

  supplier_config_list: '/supplier/',
  supplier_config_partial_update: '/supplier/'

}

/**
 * 企业列表
 */
export function supplier_list (parameter) {
  return request({
    url: userApi.supplier_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加企业
 */
export function supplier_create (parameter) {
  return request({
    url: userApi.supplier_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改企业
 */
export function supplier_update (parameter, supplier_id) {
  return request({
    url: userApi.supplier_update + supplier_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除企业
 */
export function supplier_delete (supplier_id) {
  return request({
    url: userApi.supplier_delete + supplier_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 增加标签关联
 */
export function supplier_tag_create (parameter) {
  return request({
    url: userApi.supplier_tag_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除标签关联企业
 */
export function supplier_tag_delete (parameter) {
  return request({
    url: userApi.supplier_tag_delete,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 获取企业配置
 */
export function supplier_config_list (supplier_id) {
  return request({
    url: userApi.supplier_config_list + supplier_id + '/config/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 修改企业配置
 */
export function supplier_config_partial_update (parameter, supplier_id) {
  return request({
    url: userApi.supplier_config_partial_update + supplier_id + '/config/',
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
