<template>
  <a-card :bordered="false">
    <div>
      <a-form :form="form" v-bind="formLayout">
        <a-row>
          <a-col :span="12">
            <a-form-item label="供应商名称" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['name', {rules: [{required: true, message: '请输入'}, {max:64, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="法人" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['contact', {rules: [{required: false, message: '请输入'}, {max:64, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="编码" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['code', {rules: [{required: false, message: '请输入'}, {max:6, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="统一社会信用代码" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['credit_code', {rules: [{required: false, message: '请输入'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="法人手机" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-input v-decorator="['contact_cellphone', {rules: [{required: false, message: '请输入'}, {max:20, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="是否开启订单推送">
              <a-switch v-decorator="['is_open_api', { valuePropName: 'checked' }]" />
            </a-form-item>
            <a-form-item label="供应商状态" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol" v-if="false">
              <a-select
                v-decorator="[
                  'status',
                  {
                    rules: [
                      { required: false, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option :value="1">
                  待入园
                </a-select-option>
                <a-select-option :value="2">
                  已入园
                </a-select-option>
                <a-select-option :value="3">
                  已注销
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
          v-bind="buttonCol"
        >
          <a-row>
            <a-col span="6">
              <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
            </a-col>
            <a-col span="10">
              <a-button @click="handleGoBack">返回</a-button>
            </a-col>
            <a-col span="8"></a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </div>
  </a-card>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { supplier_update, supplier_list } from '@/api/supplier'
import { emptyToUndefined } from '@/utils/util'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 3 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 6, offset: 10 }
        }
      },
      form: this.$form.createForm(this),
      id: 0
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    this.$nextTick(() => {
      // this.loadEditInfo(this.record)
      supplier_list({ id: this.$route.params.id })
        .then(res => {
          console.log(res.data, '我是数据列表@')
          const list = res.data.entries
          if (list && list.length > 0) {
            this.loadEditInfo(list[0])
          }
        })
    })
  },
  methods: {
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          values.credit_code = emptyToUndefined(values.credit_code)
          values.code = emptyToUndefined(values.code)
          values.contact = emptyToUndefined(values.contact)
          values.contact_cellphone = emptyToUndefined(values.contact_cellphone)
          supplier_update(values, this.id).then((res) => {
            console.log(res, '修改')
            if (res.code === 1000) {
              this.handleGoBack()
            }
          })
        }
      })
    },
    handleGetInfo () {},
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['id', 'name', 'credit_code', 'is_open_api', 'code', 'contact', 'contact_cellphone', 'status'])
        console.log('formData', formData)
        formData.location = String(formData.location)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
